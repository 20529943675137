import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 2xl:gap-5 gap-3 mb-5 relative" }
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = { class: "2xl:col-span-2 mt-2 lg:mt-0 lg:ml-5 2xl:ml-10" }
const _hoisted_7 = { class: "space-y-5" }
const _hoisted_8 = {
  key: 0,
  class: ""
}
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/scope-2-renewables-performance" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Setting Information")
          ])),
          _: 1
        })
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "scope-2-emission-targets" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Setting Baseline Target ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["SettingBaseline"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "mt-2 text-left text-lg 2xl:text-xl font-bold" }, " Scope 1, 2 & 3 Emissions ", -1)),
          (!$setup.GHGTargets)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[3] || (_cache[3] = [
                _createElementVNode("h3", { class: "mt-3 text-center text-lg 2xl:text-xl" }, " Please update Setting Baseline Targets ", -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                (!$setup.isLoadingGetEmissionHistory)
                  ? (_openBlock(), _createBlock($setup["ColumnChart"], {
                      key: 0,
                      class: _normalizeClass(_ctx.styles['column-size']),
                      data: $setup.dataTotalScopeHistory.dataTotal,
                      colors: ['#ffeecc', '#ffeecc', '#5e5e5e'],
                      seriesName: "Total Emission",
                      lineSeriesName: "Long term target",
                      lineData: $setup.GHGTargetsLongTermChart,
                      lineData_2: $setup.GHGTargetsShortTermChart,
                      lineSeriesName_2: "Short term target",
                      isBaseYear: true,
                      baseYear: $setup.GHGTargets.BaseYear,
                      maxValue: $setup.dataTotalScopeHistory.valueLeftColumnChart,
                      yTitle: "kWh",
                      legendStyle: {
                position: 'bottom',
                fontSize: 12,
                padding: [15, 0, 0, 0],
              },
                      isColorBaseLine: true,
                      isChartXScroll: true,
                      withExtraYScale: true,
                      lineAllowExtraYScale: false
                    }, null, 8, ["class", "data", "lineData", "lineData_2", "baseYear", "maxValue"]))
                  : _createCommentVNode("", true)
              ]))
        ])
      ])
    ])
  ]))
}