import { defineComponent as _defineComponent } from 'vue'
import SettingBaseline from "@ems/containers/GreenOptions/TheForm/SettingBaseline.Form.vue";
import { computed } from "vue";
import EmissionModule from "@ems/containers/Emission/Emission.module";
import ColumnChart from "@/components/AnyChart/ColumnChart.vue";
import SettingBaseLineModule from "@ems/containers/SettingInformation/SettingBaseLine.module";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {
  __expose();

const isLoadingGetEmissionHistory = computed(
  () => EmissionModule.isLoadingGetEmissionHistoryGetter
);
const GHGTargets = computed(() => SettingBaseLineModule.GHGTargetsGetter);
const GHGTargetsShortTermChart = computed(
  () => SettingBaseLineModule.GHGTargetsShortTermChartGetter
);
const GHGTargetsLongTermChart = computed(
  () => SettingBaseLineModule.GHGTargetsLongTermChartGetter
);
const dataTotalScopeHistory = computed(
  () => EmissionModule.TotalScopeHistoryGetter
);

const __returned__ = { isLoadingGetEmissionHistory, GHGTargets, GHGTargetsShortTermChart, GHGTargetsLongTermChart, dataTotalScopeHistory, SettingBaseline, computed, get EmissionModule() { return EmissionModule }, ColumnChart, get SettingBaseLineModule() { return SettingBaseLineModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})