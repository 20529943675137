import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "box relative" }
const _hoisted_2 = {
  class: "flex items-center -mt-7",
  style: { marginLeft: '93px' }
}
const _hoisted_3 = { class: "-ml-4" }
const _hoisted_4 = {
  class: "flex items-center -mt-7",
  style: { marginLeft: '93px' }
}
const _hoisted_5 = { class: "-ml-4" }
const _hoisted_6 = { class: "flex justify-end" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_input_number = _resolveComponent("el-input-number")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Form"], {
      as: "el-form",
      onSubmit: $setup.onSubmit,
      "validation-schema": $setup.schema,
      class: "my-4",
      "initial-values": $setup.GHGTargets
    }, {
      default: _withCtx(({ setFieldValue, isSubmitting }) => [
        _createElementVNode("div", {
          class: _normalizeClass([
          'my-3',
          ($setup.isLoadingGHGTargets || isSubmitting) && 'isSubmitting-rounded',
        ])
        }, [
          _cache[14] || (_cache[14] = _createElementVNode("h1", { class: "text-left text-xl font-bold" }, "Setting Baseline Targets", -1)),
          _createVNode($setup["Field"], { name: "BaseYear" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield inputfield__underline label-form-baseline",
                error: errorMessage,
                label: "Select Base Year"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, _mergeProps({
                    class: "w-36",
                    "model-value": value
                  }, field, {
                    "reserve-keyword": false,
                    placeholder: "",
                    onChange: (value) => $setup.handleChangeYear(value)
                  }), {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataYearsEmission, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.Value,
                          label: item.Name,
                          value: item.Value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1040, ["model-value", "onChange"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _cache[15] || (_cache[15] = _createElementVNode("hr", { class: "border-gray-400 my-5" }, null, -1)),
          _createVNode($setup["Field"], { name: "ShortTermTargetYear" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield inputfield__underline label-form-baseline",
                error: errorMessage,
                label: "Setting Short-Term Target"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, _mergeProps({
                    class: "w-36",
                    "model-value": value
                  }, field, {
                    "reserve-keyword": false,
                    placeholder: "",
                    onChange: (value) => $setup.handleChangeYear(value)
                  }), {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataYearsEmission, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.Value,
                          label: item.Name,
                          value: item.Value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1040, ["model-value", "onChange"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createVNode($setup["Field"], { name: "ShortTermTargetEmissionsReduction" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "",
                label: "Reduction of",
                error: errorMessage
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_radio_group, _mergeProps({ class: "flex flex-col" }, field, {
                    "model-value": value,
                    onChange: $setup.handleChangeShortTermTarget
                  }), {
                    default: _withCtx(() => [
                      _createVNode(_component_el_radio, {
                        label: Number(30)
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("30%")
                        ])),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_el_radio, {
                        label: Number(50)
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("50%")
                        ])),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_el_radio, {
                        label: Number(100)
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("100%")
                        ])),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 2
                  }, 1040, ["model-value"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_radio, {
              modelValue: $setup.radioShortTermManually,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.radioShortTermManually) = $event)),
              label: "1",
              onChange: 
              () => {
                setFieldValue('ShortTermTargetEmissionsReduction', 0);
              }
            
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" Others: Please specify ")
              ])),
              _: 2
            }, 1032, ["modelValue", "onChange"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_input_number, {
                class: "w-20 inputfield inputfield__underline",
                placeholder: "",
                controls: false,
                modelValue: $setup.inputShortTermManually,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.inputShortTermManually) = $event)),
                onFocus: 
                () => {
                  setFieldValue('ShortTermTargetEmissionsReduction', 1);
                  $setup.radioShortTermManually = '1';
                }
              
              }, null, 8, ["modelValue", "onFocus"]),
              _cache[8] || (_cache[8] = _createTextVNode(" % "))
            ])
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("hr", { class: "border-gray-400 my-5" }, null, -1)),
          _createVNode($setup["Field"], { name: "LongTermTargetYear" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield inputfield__underline label-form-baseline",
                error: errorMessage,
                label: "Setting Long-Term Target"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, _mergeProps({
                    class: "w-36",
                    "model-value": value
                  }, field, {
                    "reserve-keyword": false,
                    placeholder: "",
                    onChange: (value) => $setup.handleChangeYear(value)
                  }), {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataYearsEmission, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.Value,
                          label: item.Name,
                          value: item.Value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1040, ["model-value", "onChange"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createVNode($setup["Field"], { name: "LongTermTargetEmissionsReduction" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "",
                label: "Reduction of",
                error: errorMessage
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_radio_group, _mergeProps({ class: "flex flex-col" }, field, {
                    "model-value": value,
                    onChange: $setup.handleChangeLongTermTarget
                  }), {
                    default: _withCtx(() => [
                      _createVNode(_component_el_radio, {
                        label: Number(30)
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("30%")
                        ])),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_el_radio, {
                        label: Number(50)
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("50%")
                        ])),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_el_radio, {
                        label: Number(100)
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("100%")
                        ])),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 2
                  }, 1040, ["model-value"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_radio, {
              modelValue: $setup.radioLongTermManually,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.radioLongTermManually) = $event)),
              label: "1",
              onChange: 
              () => {
                setFieldValue('LongTermTargetEmissionsReduction', 0);
              }
            
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode(" Others: Please specify ")
              ])),
              _: 2
            }, 1032, ["modelValue", "onChange"]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_input_number, {
                class: "w-20 inputfield inputfield__underline",
                placeholder: "",
                controls: false,
                modelValue: $setup.inputLongTermManually,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.inputLongTermManually) = $event)),
                onFocus: 
                () => {
                  setFieldValue('LongTermTargetEmissionsReduction', 1);
                  $setup.radioLongTermManually = '1';
                }
              
              }, null, 8, ["modelValue", "onFocus"]),
              _cache[13] || (_cache[13] = _createTextVNode(" % "))
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn--red-primary",
              disabled: isSubmitting
            }, " Save ", 8, _hoisted_7)
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["validation-schema", "initial-values"])
  ]))
}