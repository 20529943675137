import { defineComponent as _defineComponent } from 'vue'
import EmissionModule from "@ems/containers/Emission/Emission.module";
import { Field, Form, configure } from "vee-validate";
import { ElMessage } from "element-plus/lib/components/message";
import { ref, onMounted, computed, watch } from "vue";
import { object, string, number } from "yup";
import SettingBaseLineModule from "@ems/containers/SettingInformation/SettingBaseLine.module";


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingBaseline.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const radioShortTermManually = ref("");
const inputShortTermManually = ref(0);

const radioLongTermManually = ref("");
const inputLongTermManually = ref(0);

const dataEmission = computed(() => EmissionModule.dataEmissionGetter);

const GHGTargets = computed(() => SettingBaseLineModule.GHGTargetsGetter);

const isLoadingGHGTargets = computed(
  () => SettingBaseLineModule.isLoadingGHGTargetsGetter
);

const handleChangeYear = async (value: any) => {
  return;
};
configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});
const dataYearsEmission = computed(
  () => EmissionModule.dataYearsEmissionGetter
);
const emissionOrigin = computed(() => EmissionModule.emissionOriginGetter);
const handleChangeShortTermTarget = (value: number | Event) => {
  if (value === 30 || value === 50 || value === 100) {
    radioShortTermManually.value = "";
    inputShortTermManually.value = 0;
  }
};

const handleChangeLongTermTarget = (value: number | Event) => {
  if (value === 30 || value === 50 || value === 100) {
    radioLongTermManually.value = "";
    inputLongTermManually.value = 0;
  }
};
const schema = object({
  BaseYear: string().required("BaseYear is a required field").nullable(),
  LongTermTargetYear: string()
    .required("LongTermTargetYear is a required field")
    .nullable(),
  ShortTermTargetYear: string()
    .required("ShortTermTargetYear is a required field")
    .nullable(),
  LongTermTargetEmissionsReduction: number().required(
    "LongTermTargetEmissionsReduction is a required field"
  ),
  ShortTermTargetEmissionsReduction: number().required(
    "ShortTermTargetEmissionsReduction is a required field"
  ),
});

const dataBaseLine = ref<number>(0);
const onSubmit = async (values: any, actions: any) => {
  isValidateLongTerm(values, actions);
  isValidateShortTerm(values, actions);
  if (values.ShortTermTargetYear < values.BaseYear) {
    ElMessage.error("The Short term target value must be a larger base year");
    return;
  }
  if (values.LongTermTargetYear < values.ShortTermTargetYear) {
    ElMessage.error(
      "The Long term target value must be a larger Short term target"
    );
    return;
  }
  const dataSubmit = {
    ...values,
    ShortTermTargetEmissionsReduction:
      radioShortTermManually.value === "1"
        ? inputShortTermManually.value
        : values.ShortTermTargetEmissionsReduction,
    LongTermTargetEmissionsReduction:
      radioLongTermManually.value === "1"
        ? inputLongTermManually.value
        : values.LongTermTargetEmissionsReduction,
  };
  await SettingBaseLineModule.submitGHGTargetsAction(dataSubmit);
  await EmissionModule.getEmissionHistory({});
  actions.resetForm();
};
const isValidateLongTerm = (values: any, actions: any) => {
  if (typeof inputLongTermManually.value === "undefined") {
    inputLongTermManually.value = 0;
  }
  if (
    isNaN(values.LongTermTargetEmissionsReduction) ||
    isNaN(inputLongTermManually.value) ||
    inputLongTermManually.value > 100 ||
    inputLongTermManually.value < 0
  ) {
    ElMessage.error("The long term target value range from 0 to 100");
    actions.resetForm();
    renewLongTarget();

    return false;
  }

  if (radioLongTermManually.value === "1") {
    values.Target = inputLongTermManually.value;
  }
  return true;
};

const isValidateShortTerm = (values: any, actions: any) => {
  if (typeof inputShortTermManually.value === "undefined") {
    inputShortTermManually.value = 0;
  }
  if (
    isNaN(values.ShortTermTargetEmissionsReduction) ||
    isNaN(inputShortTermManually.value) ||
    inputShortTermManually.value > 100 ||
    inputShortTermManually.value < 0
  ) {
    ElMessage.error("The short term target value range from 0 to 100");
    actions.resetForm();
    renewShortTarget();
    return false;
  }

  if (radioShortTermManually.value === "1") {
    values.Target = inputShortTermManually.value;
  }
  return true;
};

watch(
  () => emissionOrigin.value,
  () => {
    dataBaseLine.value =
      emissionOrigin.value * (dataEmission.value.Factor / 100);
  }
);
watch(
  () => dataEmission.value.Factor,
  () => {
    dataBaseLine.value =
      emissionOrigin.value * (dataEmission.value.Factor / 100);
  }
);
const renewShortTarget = () => {
  if (
    GHGTargets.value.ShortTermTargetEmissionsReduction === 30 ||
    GHGTargets.value.ShortTermTargetEmissionsReduction === 50 ||
    GHGTargets.value.ShortTermTargetEmissionsReduction === 100
  ) {
    radioShortTermManually.value = "";
    inputShortTermManually.value = 0;
  } else {
    radioShortTermManually.value = "1";
    inputShortTermManually.value = GHGTargets.value
      .ShortTermTargetEmissionsReduction
      ? parseFloat(
          GHGTargets.value.ShortTermTargetEmissionsReduction.toFixed(0)
        )
      : GHGTargets.value.ShortTermTargetEmissionsReduction;
  }
};

const renewLongTarget = () => {
  if (
    GHGTargets.value.LongTermTargetEmissionsReduction === 30 ||
    GHGTargets.value.LongTermTargetEmissionsReduction === 50 ||
    GHGTargets.value.LongTermTargetEmissionsReduction === 100
  ) {
    radioLongTermManually.value = "";
    inputLongTermManually.value = 0;
  } else {
    radioLongTermManually.value = "1";
    inputLongTermManually.value = GHGTargets.value
      .LongTermTargetEmissionsReduction
      ? parseFloat(GHGTargets.value.LongTermTargetEmissionsReduction.toFixed(0))
      : GHGTargets.value.LongTermTargetEmissionsReduction;
  }
};
onMounted(async () => {
  await EmissionModule.fetchYearsEmission();
  await EmissionModule.getEmissionHistory({});
  await SettingBaseLineModule.getGHGTargetsAction();
  renewShortTarget();
  renewLongTarget();
});

const __returned__ = { radioShortTermManually, inputShortTermManually, radioLongTermManually, inputLongTermManually, dataEmission, GHGTargets, isLoadingGHGTargets, handleChangeYear, dataYearsEmission, emissionOrigin, handleChangeShortTermTarget, handleChangeLongTermTarget, schema, dataBaseLine, onSubmit, isValidateLongTerm, isValidateShortTerm, renewShortTarget, renewLongTarget, get EmissionModule() { return EmissionModule }, get Field() { return Field }, get Form() { return Form }, get configure() { return configure }, get ElMessage() { return ElMessage }, ref, onMounted, computed, watch, get object() { return object }, get string() { return string }, get number() { return number }, get SettingBaseLineModule() { return SettingBaseLineModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})